import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import Img from 'gatsby-image'
import { Link, graphql } from 'gatsby'

const ProductsPage = ({ data }) => {
  const allProducts = data.allShopifyProduct.edges
  console.log(data.allShopifyProduct.edges)
  return (
    <Layout language="nl" en="products" nl="producten">
      <SEO title="Producten" keywords={[`lamp`, `furniture`, `design`]} />
      <div className="articleHero gridFullWidth">
        <h1>Onze ontwerpen</h1>
      </div>
      <div className="gridFullWidth allProductsContainer">
        {allProducts.map(singleProduct => {
          return (
            <Link
              className="singleProductContainer"
              to={singleProduct.node.handle}
            >
              <div className="allProductsImageOverlay">
                <Img
                  fluid={
                    singleProduct.node.images[0].localFile.childImageSharp.fluid
                  }
                  key={singleProduct.node.images[0].id}
                  alt={singleProduct.node.title}
                />
                {singleProduct.node.images[1] && (
                  <Img
                    fluid={
                      singleProduct.node.images[1].localFile.childImageSharp
                        .fluid
                    }
                    key={singleProduct.node.images[1].id}
                    alt={singleProduct.node.title}
                  />
                )}
              </div>
              <div className="allProductsInfo">
                <div className="titlePrice">
                  <h4>
                    {singleProduct.node.title}{' '}
                    {/* <span class="animatedGradient">NIEUW</span> */}
                  </h4>
                  <p>€ {singleProduct.node.variants[0].price}</p>
                </div>
                <p style={{ opacity: 0.5 }}>Tafellamp</p>
                <div style={{ display: `flex`, justifyContent: `center` }}>
                  <p className="productCTA">Bekijk details</p>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query AllProductsNL {
    allShopifyProduct {
      edges {
        node {
          id
          images {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
          productType
          handle
          variants {
            price
          }
        }
      }
    }
  }
`
